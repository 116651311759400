import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Layout} from "antd";
import MainLayout from './pages/MainLayout';
import Home from "./pages/Home";
import Register from "./pages/Register";

const { Content } = Layout;

const App = () => {
  return (
    <Router>
      <MainLayout>
        <Content>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/register" element={<Register />} />
          </Routes>
        </Content>
      </MainLayout>
    </Router>
  );
};

export default App;

